import Container from '../Container'

export default function PaymentSuccess(): JSX.Element {
  return (
    <Container>
      <div className='payment-success-message'>
        Your payment has been successfully recorded.
      </div>
      <a href='/' className='go-back-link'>Go Back</a>
    </Container>
  )
}