import React from 'react';

interface ContainerProps {
  children: React.ReactNode;
}

export default function Container({ children }: ContainerProps): JSX.Element {
  return (
    <div id='container'>
      <Header/>
      <div id='content'>
        {children}
      </div>
      <Footer/>
    </div>
  )
}

function Header(): JSX.Element {
  return (
    <header>
      {/** Header image */}
    </header>
  )
}

function Footer(): JSX.Element {
  return (
    <footer>
      <div id='footer-container'>
        <div id='footer-col-1'>
          <p className='footer-header'>Links</p>
          <div className='footer-col-items'>
            <li>
              <a href='https://smartcloud-static-content.smartsys.io/ncs/terms.html'>
                {'Parking Terms & conditions'}
              </a>
            </li>
          </div>
        </div>
      </div>
    </footer>
  )
}