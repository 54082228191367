import { PortalResponse } from '../../../type-definitions/src/api';

export const API_KEY = 'kPMPcqrQ5Ii7Ss4v';

export function getBaseURL(): string {
    return 'https://api.scp.smartsys.io';
}

export async function getParkingDetails(orgId: string, breachId: string, plate: string): Promise<PortalResponse> {
  const url = new URL(`contravention/organizations/${orgId}/parking/portal`, getBaseURL());
  url.searchParams.append('api-key', API_KEY);
  url.searchParams.append('breachId', breachId);
  url.searchParams.append('plate', plate);

  const response = await fetch(url.toString());
  if (response.status === 200) {
    return await response.json();
  } else {
    const status = response && response.status;
    throw new Error(String(status));
  }
}