import { ReactNode, useEffect, useState } from 'react';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import AttachMoney from '@material-ui/icons/AttachMoney';
import LocationSearching from '@material-ui/icons/LocationSearching';
import Policy from '@material-ui/icons/Policy';
import Receipt from '@material-ui/icons/Receipt';
import Schedule from '@material-ui/icons/Schedule';

import { PortalResponse, CustomerResource, CustomerOffence } from '../../../type-definitions/src/api';
import { getParkingDetails } from '../../../utils/src/breach-portal/parking';

import './styles.css';
import { PayBreach } from './PayBreach';

interface BreachDetailProps {
  orgId: string;
  breach: string;
  plate: string;
  handleBackButton: () => void;
  titleText: string;
  breachNumberText: string;
  cancelledCaseErrorText: string;
  closedCaseErrorText: string;
  caseNotFoundErrorText: string;
  format: 'table' | 'list';
  showImages?: boolean;
  /** Pay Now button appears if supplied */
  payNow?: {
    buttonText: string;
    successUrl: string;
    cancelUrl: string;
  }
}

export function BreachDetail({ orgId, handleBackButton, format, payNow, breach, plate, showImages,
  titleText, breachNumberText, cancelledCaseErrorText, closedCaseErrorText, caseNotFoundErrorText }: BreachDetailProps)
  : JSX.Element {
  const [parkingCase, setParkingCase] = useState<PortalResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<{ message: string, type: 'success' | 'error' }>();

  useEffect(() => {
    getBreach(plate, breach);
  }, [plate, breach]);

  const getBreach = async (plate: string, breach: string) => {
    try {
      const caseDetails = await getParkingDetails(orgId, breach, plate);
      if (caseDetails.case.cancelled) {
        setErrorMessage({ message: cancelledCaseErrorText, type: 'success' });
      } else if (caseDetails.case.state === 'Closed') {
        setErrorMessage({ message: closedCaseErrorText, type: 'success' });
      }
      setParkingCase(caseDetails);
    } catch (e: Error) {
      if (e.message === '404') {
        setErrorMessage({ message: caseNotFoundErrorText, type: 'success' });
      } else {
        setErrorMessage({ message: 'An unexpected error occurred when finding your request.', type: 'error' });
      }
    } finally {
      if (isLoading) {
        setIsLoading(false);
      }
    }
  }

  return (
    <div className='breach-container'>
      {!isLoading ? 
        <>
          <div className='header'>
            <IconButton component='span' onClick={() => handleBackButton()} id='back-button'>
              <ArrowBack style={{ color: 'white' }}/>
            </IconButton>
            <Typography variant='h5' className='container-title' component='span'>
              {titleText}: {plate.toUpperCase()}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: 'grey' }}/>
          {parkingCase ?
            <>
              {format === 'table' && <DetailTable breachNumberText={breachNumberText} parkingCase={parkingCase}/>}
              {format === 'list' && <DetailList breachNumberText={breachNumberText} parkingCase={parkingCase}/>}
              {payNow && <PayBreach
                orgId={orgId}
                buttonText={payNow.buttonText}
                successUrl={payNow.successUrl}
                cancelUrl={payNow.cancelUrl}
                caseUri={parkingCase.case.uri}
                breachId={parkingCase.breachNotice!.breachId ?? parkingCase.ticket!.breachId}
                stripeKey={'pk_live_51JHfmaHhrIZ6DI82HjSpvz8dGI3NfbNLqZCIna26z6VEvdJueGBQCol0Yvle8cpjYx9DblSq6fVu7KczqXXnMs4U00JDBaZuZd'}
              />}
              {showImages && <PhotoGallery resources={parkingCase.resources}/>}
            </>
          : <div className={errorMessage?.type === 'success' ? 'error-message-success' : 'error-message'}>
            {errorMessage?.message ?? 'Error!'}
          </div>}

        </> : 'Loading...'}
    </div>
  );
}

interface PhotoGalleryProps {
  resources: CustomerResource[];
}

function PhotoGallery({ resources }: PhotoGalleryProps) {
  const Gallery = require('react-photo-gallery');
  const photos = resources.filter((it) => it.contentType && it.contentType.startsWith('image/'))
    .map((img) => ({ src: img.publicUrl!, width: 1, height: 1 }));

  return (
    <>
      {photos.length > 0 ? 
        <Gallery columns={1} photos={photos}/>
      : <Typography>No breach pictures found</Typography>}
    </>
  )
}

interface DetailListProps {
  breachNumberText: string;
  parkingCase: PortalResponse;
}

function DetailList({ breachNumberText, parkingCase }: DetailListProps) {
  const formatDate = (time?: string, options?: Intl.DateTimeFormatOptions): string | undefined => {
    if (!time) {
      return undefined;
    }
    return new Intl.DateTimeFormat('en', options).format(new Date(time));
  }

  const formatAmountOwing = (offence: CustomerOffence): ReactNode => {
    if (offence.discount) {
      return (
        <>
          <del>{offence.offenceFeeFormatted}</del>
          <span style={{ marginLeft: '10px' }}>{offence.discount.discountedFeeFormatted} - Early payment discount ({offence.discount.discountAmountFormatted} off)</span>
        </>
      );
    }
    return offence.offenceFeeFormatted;
  }

  return (
    <div className='list-container'>
      <List>
        <DetailListItem
          primary={breachNumberText}
          secondary={parkingCase.breachNotice?.breachId}
          Icon={Receipt}
        />
        <DetailListItem
          primary='Date Issued'
          secondary={formatDate(parkingCase.overstayIssueTime, { year: 'numeric', month: 'short', day: 'numeric' }) ?? 'N/A'}
          Icon={Schedule}
        />
        <DetailListItem
          primary='Location'
          secondary={Boolean(parkingCase.location.address) ? parkingCase.location.address
            : Boolean(parkingCase.location.name) ? parkingCase.location.name
            : parkingCase.bayName}
          Icon={LocationSearching}
        />
        <DetailListItem
          primary='Offence'
          secondary={parkingCase.offences[0]?.offenceMessage ?? 'Other'}
          Icon={Policy}
        />
        <DetailListItem
          primary='Amount Owing'
          secondary={formatAmountOwing(parkingCase.offences[0])}
          Icon={AttachMoney}
        />
      </List>
    </div>
  );
}

interface DetailListItemProps {
  primary: ReactNode;
  secondary?: ReactNode;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

function DetailListItem({ primary, secondary, Icon }: DetailListItemProps): JSX.Element {
  return (
    <ListItem>
      <ListItemAvatar>
        <Icon/>
      </ListItemAvatar>
      <ListItemText
        primary={
          <span className='list-item-primary'>
            {primary}
          </span>
        }
        secondary={
          <span className='list-item-secondary'>
            {secondary}
          </span>
        }
      />
    </ListItem>
  )
}

interface DetailTableProps {
  breachNumberText: string;
  parkingCase: PortalResponse;
}

function DetailTable({ breachNumberText, parkingCase }: DetailTableProps) {
  const formatDate = (time?: string, options?: Intl.DateTimeFormatOptions): string | undefined => {
    if (!time) {
      return undefined;
    }
    return new Intl.DateTimeFormat('en', options).format(new Date(time));
  }
  return (
    <table id='breach-table'>
    <thead>
      <tr className='breach-table-row'>
        <TableHead label={breachNumberText}/>
        <TableHead label='Date Issued'/>
        <TableHead label='Time Issued'/>
        <TableHead label='Location'/>
        <TableHead label='Amount'/>
        <TableHead label='Violation'/>
      </tr>
    </thead>
    <tbody>
      <tr className='breach-table-row'>
        <TableCell value={parkingCase.breachNotice?.breachId ?? 'N/A'}/>
        <TableCell value={formatDate(parkingCase.overstayIssueTime, { year: 'numeric', month: 'short', day: 'numeric' }) ?? 'N/A'}/>
        <TableCell value={formatDate(parkingCase.overstayIssueTime, { hour: 'numeric', minute: 'numeric', second: 'numeric' }) ?? 'N/A'}/>
        <TableCell value={parkingCase.location.address ? `${parkingCase.location.name ?? ''} - near ${parkingCase.location.address}` : 'N/A'}/>
        <TableCell value={`$${parkingCase.offences[0].offenceFee ? parkingCase.offences[0].offenceFee.toFixed(2) : '0.00'}`}/>
        <TableCell value={parkingCase.offences[0].offenceMessage ?? 'N/A'}/>
      </tr>
    </tbody>
  </table>
  )
}

interface TableHeadProps {
  label: ReactNode,
}

function TableHead({ label }: TableHeadProps): JSX.Element {
  return (
    <th className='breach-table-head'>
      <Typography className='title-header-label'>{label}</Typography>
    </th>
  )
}

interface TableCellProps {
  value: ReactNode;
}

function TableCell({ value }: TableCellProps): JSX.Element {
  return (
    <td>
      <Typography className='field-value'>{value}</Typography>
    </td>
  );
}
