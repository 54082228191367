import { Switch, Route, useHistory } from 'react-router-dom';
import Container from './Container';
import BreachViewer from './components/BreachViewer';
import { SearchBreach } from '@shared/components';
import PaymentSuccess from './components/PaymentSuccess';


export function Main(): JSX.Element {
  const history = useHistory();
  const handleSubmit = async (plate: string, breach: string) => {
    const params = new URLSearchParams();
    params.set('breach', breach);
    params.set('plate', plate);
    history.push(`search?${params.toString()}`);
  }

  return (
    <Container>
      <h3>Pay My Parking Ticket</h3>
      <SearchBreach plateInputText='Plate' breachInputText='Ticket Number' handleSubmit={handleSubmit} />
    </Container>
  )
}

export default function App(): JSX.Element {
  return (
      <Switch>
        <Route path='/search'>
          <BreachViewer/>
        </Route>
        <Route path='/payment-success'>
          <PaymentSuccess/>
        </Route>
        <Route path='/'>
          <Main/>
        </Route>
      </Switch>
  )
}
