import { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import { getBaseURL, API_KEY } from '../../../utils/src/breach-portal/parking';

import './styles.css';

interface PayBreachProps {
  buttonText: string;
  stripeKey: string;
  orgId: string;
  caseUri: string;
  breachId: string;
  successUrl: string;
  cancelUrl: string;
}

interface StripePaymentsResponse {
  stripeSessionId: string;
}

async function getSessionId(orgId: string, caseUri: string, breachId: string, successUrl: string, cancelUrl: string): Promise<string> {
  const url = new URL(`contravention/organizations/${orgId}/parking/portal/stripe-payments`, getBaseURL());
  url.searchParams.append('api-key', API_KEY);

  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      caseUri,
      breachId,
      successUrl,
      cancelUrl,
    })
  });
  if (response.status === 201) {
    const body: StripePaymentsResponse = await response.json();
    return body.stripeSessionId;
  } else {
    throw new Error();
  }
}

function useScript(url: string): void {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
}

export function PayBreach({ buttonText, orgId, caseUri, breachId, successUrl, cancelUrl, stripeKey }: PayBreachProps) {
  const [errorMessage, setErrorMessage] = useState<string>();
  useScript('https://js.stripe.com/v3/')
  const loadSession = async () => {
    if (errorMessage) {
      setErrorMessage(undefined);
    }
    const stripe = (window as any).Stripe(stripeKey);
    try { 
      const sessionId = await getSessionId(orgId, caseUri, breachId, successUrl, cancelUrl);
      await stripe.redirectToCheckout({ sessionId });
    } catch (e) {
      console.log(e);
      setErrorMessage('An unexpected error occured. Try again later.');
    }
    
  }
  return (
    <div className='pay-breach-button-container'>
      <Button className='pay-breach-button' variant='contained' onClick={loadSession}>
        {buttonText}
      </Button>
      {errorMessage && <div className='pay-breach-error'>{errorMessage}</div>}
    </div>
  )
}
