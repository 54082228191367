import { useState, FormEvent } from 'react';
import { Typography, CircularProgress, Button } from '@material-ui/core';
import { Input } from '../Input';

import './styles.css';

interface SearchBreachProps {
  title?: string,
  plateInputText: string;
  breachInputText: string;
  handleSubmit: (plate: string, breach: string) => Promise<void>;
  errorMessage?: string;
}

export function SearchBreach({ title, plateInputText, breachInputText, handleSubmit, errorMessage }: SearchBreachProps): JSX.Element {
  const [breach, setBreach] = useState<string>('');
  const [plate, setPlate] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    await handleSubmit(plate, breach);
  }

  return (
    <div className='breach-container'>
    {title && <Typography variant='h4' className='container-title'>{title}</Typography>}
    <form onSubmit={handleFormSubmit}>
      <div id='form-inputs'>
        <Input label={breachInputText} onChange={(value: string) => setBreach(value)} value={breach}/>
        <Input label={plateInputText} onChange={(value: string) => setPlate(value)} value={plate}/>
      </div>
      <Typography component='p'>
        <span className='required-text'>*</span> Indicates required fields
      </Typography>
      {errorMessage && <div><Typography id='breach-error' component='span'>{errorMessage}</Typography></div>}
      {isLoading ?
        <div id='submit-loading'><CircularProgress/></div>
      : <Button id='search-breach-button' type='submit' variant='contained'>SEARCH</Button>}
    </form>
    </div>
  )
}
