import { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import Container from '../Container'
import { BreachDetail } from '@shared/components';

export default function BreachViewer(): JSX.Element {
  const { search } = useLocation<string>();
  const history = useHistory();
  const [plate, setPlate] = useState<string>();
  const [breach, setBreach] = useState<string>();

  useEffect(() => {
    const params = new URLSearchParams(search)
    const paramPlate = params.get('plate');
    const paramBreach = params.get('breach');
    
    if (!paramPlate || !paramBreach) {
      history.push('/');
      return;
    }
    setPlate(paramPlate);
    setBreach(paramBreach);
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {plate && breach && 
        <BreachDetail
          orgId='ncs'
          format='list'
          plate={plate}
          breach={breach}
          titleText='Ticket Summary'
          breachNumberText='Ticket Number'
          cancelledCaseErrorText='Your ticket has been cancelled, no further action is required.'
          closedCaseErrorText='Your ticket has been closed, no further action is required.'
          caseNotFoundErrorText='We could not find your ticket details!'
          handleBackButton={() => history.goBack() }
          payNow={{
            buttonText: 'Pay Now',
            successUrl: `https://${window.location.hostname}/payment-success`,
            cancelUrl: window.location.href,
          }}
        />
      }
    </Container>
  )
}